import React, { useState } from "react";
import './Products.css'; // Importa o CSS para estilização
import RedirectButton from "../RedirectButton";

// Importa os arquivos SVG
import RendaVariavelIcon from '../../assets/img/svg/renda-variavel.svg';
import FundoInvestimentoIcon from '../../assets/img/svg/fundo-investimento.svg';
import FiiIcon from '../../assets/img/svg/fii.svg';
import AcaoTradingIcon from '../../assets/img/svg/acoes-trading.svg';
import AgroIcon from '../../assets/img/svg/agro.svg';
import Modal from "components/Modal";

import companyMacroLogo from '../../assets/img/png/LagoMacroAgro.png'; // Certifique-se de que o caminho está correto

const services = [
    {
        icon: RendaVariavelIcon,
        title: 'MACRO PRIVATE',
        context: 'Somos Individuais \n \n Soluções completas de portfólio',
        route: '/private' // Rota correspondente
    },
    {
        icon: FundoInvestimentoIcon,
        title: 'MACRO GLOBAL',
        context: 'Somos Globais \n \n Soluções em Investimentos no exterior',
        route: '/global' // Rota correspondente
    },
    {
        icon: FiiIcon,
        title: 'MACRO INVESTIMENTOS',
        context: 'Somos Alocação \n \n Soluções na medida do seu objetivo.',
        route: '/investimentos' // Rota correspondente
    },
    /*
    {
        icon: AcaoTradingIcon,
        title: 'MACRO TRADER',
        context: 'Somos Performance \n \n Soluções para quem vive o mercado.',
        route: '/trader' // Rota correspondente
    },
    */
    {
        icon: AgroIcon,
        title: 'MACRO AGRO',
        context: 'Somos Agro \n \n Soluções de Silo Virtual para commodities.',
        route: '/agro' // Rota correspondente
    },
];

const Products: React.FC = () => {
    const [isModalOpen, setIsModalOpen] = useState(false); // Estado para controlar o modal

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    // Função para transformar \n em quebras de linha
    const formatTextWithLineBreaks = (text: string) => {
        return text.split('\n').map((str, index) => (
            <React.Fragment key={index}>
                {str}
                <br />
            </React.Fragment>
        ));
    };

    const handleServiceClick = (serviceTitle: string, route: string) => {
        window.location.href = route;
    };

    return (
        <div className="products-section">
            <h2>UM NOVO MERCADO, UMA NOVA ASSESSORIA</h2>
            <div className="cards-container">
                {services.map((service, index) => (
                    <div 
                        className="service-card" 
                        key={index}
                        onClick={() => handleServiceClick(service.title, service.route)} // Chama a função de navegação
                    >
                        <div className="service-icon">
                            <img src={service.icon} alt={service.title} /> {/* Exibe o SVG como imagem */}
                        </div>
                        <h3>{service.title}</h3>

                        <div style={{ fontFamily: 'Roboto, sans-serif', fontSize: 20 }}>
                            <p>{formatTextWithLineBreaks(service.context)}</p> {/* Aplica a função para formatar o texto */}
                        </div>
                    </div>
                ))}
            </div>

            <RedirectButton />

        </div>
    );
};

export default Products;
