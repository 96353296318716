import React, { useEffect, useState } from 'react';
import './LoginModal.css';
import logo from '../../assets/img/png/LogoMacro.png';
import api from 'services/api';

interface LoginModalProps {
  isOpen: boolean;
  onClose: () => void;
  onLoginSuccess: (token: string) => void;
}

const LoginModal: React.FC<LoginModalProps> = ({ isOpen, onClose, onLoginSuccess }) => {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [tempPass, setTempPass] = useState(false);
  const [textButtonCancel, setTextButtonCancel] = useState('Cancelar');

  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    if (isOpen) {
      setEmail('');
      setPassword('');
      setError('');
      setSuccess('');
      setNewPassword('');
      setConfirmNewPassword('');
      setTextButtonCancel('Cancelar');
      setTempPass(false)
    }
  }, [isOpen]);

  const handleSubmit = async (e: React.FormEvent) => {

    setIsLoading(true)

    setSuccess('')
    setError('')

    e.preventDefault();

    if ( tempPass ){

      if (tempPass) {
        // Verifica se as novas senhas são iguais
        if (newPassword !== confirmNewPassword) {
          setError('As novas senhas não coincidem!');
          return;
        }
      }

      try {
        // Faz a requisição de login
        const response = await api.post('/password', {
          email,
          password,
          newPassword,
        });
  
        if (response.status === 200) {

          setTempPass(false)
          setError('')
          setSuccess('Senha alterada com sucesso. Realize o login novamente!')
          setPassword('')
  
        } else {
          setError('Email ou senha incorretos, por favor, verifique.');
        }
      } catch (error) {
        setIsLoading(false)
        setError('Não foi possível realizar o login, tente novamente.');
      }

    }else{

      try {
        // Faz a requisição de login
        const response = await api.post('/makelogin', {
          email,
          password,
        });
  
        if (response.status === 200) {
          const { token, temp, n } = response.data;

          console.log(temp)

          if ( temp ){
            setIsLoading(false)
            setTempPass(true)
            return
          }

          localStorage.setItem('n', n)
          
          onLoginSuccess(token);
          setTempPass(temp);
  
          // Se não é necessário alterar a senha temporária, fecha o modal
          if (!temp) {
            onClose();
          }
        } else {
          setError('Email ou senha incorretos, por favor, verifique.');
        }
      } catch (error) {
        setIsLoading(false)
        setError('Não foi possível realizar o login, tente novamente.');
      }

    }

    setIsLoading(false)

  };

  const handleForgotMyPassword = async () => {

    if (!email) {
      setError('Informe seu e-mail!');
      return;
    }

    try {

      setIsLoading(true)

      const response = await api.post('/forgot', { email });

      if (response.status === 200) {
        
        setSuccess('Enviamos uma nova senha para seu email');
        setError('');
        setTextButtonCancel('Fechar');

      } else {
        setError('Email não encontrado, favor verificar!');
      }
    } catch (error) {
      setError('Email não encontrado, favor verificar!');
    } finally {
      setIsLoading(false)
    }
  };

  if (!isOpen) return null;

  return (
    <div className="login-modal">
      <div className="login-modal-content">
        <img src={logo} alt="Logo Macro" width={150} style={{ marginTop: 20, marginBottom: 30 }} />
        <p style={{ color: '#fff' }}>Área do Cliente</p>

        <form onSubmit={handleSubmit}>
          <div>
            <label>Email:</label>
            <input
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              placeholder="e-mail"
              style={{ width: '93%' }}
            />
          </div>
          <div>
            <label>Senha:</label>
            <input
              type="password"
              value={password}
              placeholder="Senha"
              onChange={(e) => setPassword(e.target.value)}
              required
              style={{ width: '93%' }}
            />
          </div>

          {tempPass && (
            <>
              <div>
                <label>Nova Senha:</label>
                <input
                  type="password"
                  value={newPassword}
                  placeholder="Nova Senha"
                  onChange={(e) => setNewPassword(e.target.value)}
                  required={tempPass} // Tornar obrigatório apenas se tempPass for true
                  style={{ width: '93%' }}
                />
              </div>
              <div>
                <label>Confirmar Nova Senha:</label>
                <input
                  type="password"
                  value={confirmNewPassword}
                  placeholder="Confirmar Nova Senha"
                  onChange={(e) => setConfirmNewPassword(e.target.value)}
                  required={tempPass} // Tornar obrigatório apenas se tempPass for true
                  style={{ width: '93%' }}
                />
              </div>
            </>
          )}

          <p
            style={{
              color: '#fff',
              fontSize: 12,
              textAlign: 'center',
              cursor: 'pointer',
              marginBottom: 20,
              display: tempPass ? 'none' : 'flex'
            }}
            onClick={handleForgotMyPassword}
          >
            Esqueci minha senha
          </p>

          {error && <p className="error">{error}</p>}
          {success && <p className="success">{success}</p>}

          <div className="button-container" style={{ marginTop: 30, display: 'flex', alignItems: 'center', marginLeft: 20 }}>
            <button
              style={{ marginRight: 20, width: 120, height: 40 }} 
              onClick={onClose}
              disabled={isLoading}
            >
              {textButtonCancel}
            </button>

            <button 
              style={{ height: 40, display: 'flex', alignItems: 'center', justifyContent: 'center', width: 120, fontSize: 15 }}
              type="submit"
            >
              {isLoading ? <div className="spinner"></div> : 'Entrar'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginModal;
