import React, { useState, useEffect } from 'react';

const RedirectButton: React.FC = () => {
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    // Função para ajustar o estado baseado no tamanho da tela
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Se for menor ou igual a 768px, define como mobile
    };

    // Chama a função ao montar o componente e ao redimensionar a tela
    window.addEventListener('resize', handleResize);

    // Chama imediatamente após o carregamento da página para ajustar o estado inicial
    handleResize();

    // Remove o listener quando o componente desmontar
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <a
      href="https://portal.eqi.com.br/cadastro/?assessor=2601474"
      target="_blank"
      rel="noopener noreferrer"
      style={{
        display: 'inline-block',
        padding: isMobile ? '10px' : '20px', // Padding menor em telas móveis
        fontWeight: "bold",
        fontSize: isMobile ? '14px' : '18px', // Fonte menor em telas móveis
        borderRadius: 10,
        borderColor: '#323e56',
        borderWidth: 1,
        backgroundColor: '#323e56',
        textDecoration: 'none',
        color: '#fff',
        cursor: 'pointer',
        transition: 'background-color 0.3s ease, color 0.3s ease',
        width: 'auto',
      }}
      onMouseEnter={(e) => {
        e.currentTarget.style.backgroundColor = '#2d2e3b';
        e.currentTarget.style.color = '#fff';
        e.currentTarget.style.cursor = 'pointer';
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.backgroundColor = '#323e56';
        e.currentTarget.style.color = '#fff';
        e.currentTarget.style.cursor = 'pointer';
      }}
    >
      ABRA SUA CONTA
    </a>
  );
};

export default RedirectButton;
