import React from 'react';
import { Card, Col, Row, Statistic } from 'antd';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { CheckCircleOutlined, UserAddOutlined, ExceptionOutlined, RubyOutlined, WarningOutlined, CommentOutlined } from '@ant-design/icons';

// Dados de exemplo para o gráfico
const data = [
    { date: '01/10', acessos: 100, finite: 150 },  // Adiciona o campo 'finite'
    { date: '02/10', acessos: 300, finite: 300 },
    { date: '03/10', acessos: 500, finite: 450 },
    { date: '04/10', acessos: 700, finite: 600 },
    { date: '05/10', acessos: 900, finite: 850 },
    { date: '06/10', acessos: 1200, finite: 1100 },
    { date: '07/10', acessos: 1500, finite: 1300 },
  ];

const DashboardController: React.FC = () => {
  return (
    <div style={{ padding: '30px' }}>
      <h2 style={{ textAlign: 'left', fontStyle: 'italic' }}>Dashboard</h2>
      
      {/* Campanhas Section */}
      <Row gutter={16} style={{ marginBottom: '20px' }}>
        <Col span={8}>
          <Card style={{ backgroundColor: '#f5f8ff' }}>
            <Statistic
              title="Leads Pendentes"
              value={12}
              valueStyle={{ color: '#1890ff' }}
              prefix={<RubyOutlined />}
              style={{ fontWeight: 'bold'}}
            />
          </Card>
        </Col>
        <Col span={8}>
            <Card style={{ backgroundColor: '#fff9eb' }}>
            <Statistic
                title="Leads Perdidos"
                value={8}
                valueStyle={{ color: '#cf1322' }}
                prefix={<WarningOutlined />}
                style={{ fontWeight: 'bold'}}
                />
            </Card>
        </Col>
        <Col span={8}>
          <Card style={{ backgroundColor: '#f5fff5' }}>
            <Statistic
              title="Leads Convertidos"
              value={45}
              valueStyle={{ color: '#3f8600' }}
              prefix={<UserAddOutlined />}
              style={{ fontWeight: 'bold'}}
            />
          </Card>
        </Col>
      </Row>

      {/* Mensagens Section */}
      <Row gutter={16}>
        <Col span={8}>
          <Card style={{ backgroundColor: '#f5fff5' }}>
            <Statistic
              title="Acessos ao Portal"
              value={1589}
              valueStyle={{ color: '#3f8600' }}
              prefix={<CheckCircleOutlined />}
              style={{ fontWeight: 'bold'}}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card style={{ backgroundColor: '#f5f8ff' }}>
            <Statistic
              title="Interações com o Finite"
              value={120}
              valueStyle={{ color: '#1890ff' }}
              prefix={<CommentOutlined />}
              style={{ fontWeight: 'bold'}}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card style={{ backgroundColor: '#fff9eb' }}>
            <Statistic
              title="Relatórios Baixados"
              value={352}
              valueStyle={{ color: '#faad14' }}
              prefix={<ExceptionOutlined />}
              style={{ fontWeight: 'bold'}}
            />
          </Card>
        </Col>
      </Row>

        {/* Gráfico de Linhas para a Evolução de Mensagens Enviadas */}
        <Card style={{ marginTop: '20px' }}>
            <h3>Evolução de Acessos ao Portal x Interações com o Finite</h3>
            <ResponsiveContainer width="100%" height={300}>
            <LineChart data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="acessos" stroke="#8884d8" activeDot={{ r: 8 }} />
                <Line type="monotone" dataKey="finite" stroke="#82ca9d" /> {/* Segunda linha */}
            </LineChart>
            </ResponsiveContainer>
        </Card>
    </div>
  );
};

export default DashboardController;
