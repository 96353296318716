import React from 'react';
import './Footer.css';
import { SocialIcon } from 'react-social-icons';
import logo from '../../assets/img/png/LogoMacro.png';
import LogoMacroOnly from '../../assets/img/png/LogoMacroOnly.png';

const Footer: React.FC = () => {
    return (
        <footer className="footer">
            <div className="footer-top">
                <img className="logo-footer" src={logo} alt="Logo Macro" />
                <div className="social-icons">
                    <SocialIcon url={'https://www.instagram.com/macroinv'} network={'instagram'} bgColor="#323e56" />
                    <SocialIcon url={'https://www.youtube.com/@Mesamacro'} network={'youtube'} bgColor="#323e56" />
                </div>
            </div>

            <div className="footer-content">
                <div style={{ fontFamily: 'Roboto, sans-serif', fontSize: 14 }}>
                    <p>A Macro — Assessor de Investimentos Ltda. é uma empresa de assessoria de investimentos devidamente registrada na Comissão de Valores Mobiliários (CVM), na forma da Resolução CVM 178/2023.</p>
                    <p>A Macro — Assessor de Investimentos Ltda. atua no mercado financeiro credenciada à EQI Corretora CTVM S/A, o que pode ser verificado através do site da CVM (www.cvm.gov.br &gt; Assuntos &gt; Regulados &gt; Regulados CVM &gt; Agentes Autônomos &gt; Cadastral Geral na Central de Sistemas da CVM) ou através do site da ANCORD para escritórios credenciados a partir de outubro de 2012 (https://credenciamento.ancord.org.br/login.html &gt; Consulta Pública &gt; Sociedade de Assessores de Investimentos) ou através do site da própria EQI CTVM S/A (www.eqicorretora.com.br &gt; Compliance e Regras &gt; Assessores de Investimento – B2B &gt; Selecione o estado e a cidade que deseja pesquisar &gt; Veja a lista dos Assessores de Investimentos).</p>
                    <p>Na forma da legislação da CVM, o Assessor de Investimentos não pode administrar ou gerir o patrimônio de investidores. O Assessor de Investimentos é um intermediário e depende da autorização prévia do cliente para realizar operações no mercado financeiro. Esta mensagem e seus anexos contêm informações confidenciais destinadas a indivíduo e propósito específicos, sendo protegida por lei.</p>
                    <p>Caso você não seja a pessoa a quem foi dirigida a mensagem, deve apagá-la. É terminantemente proibida a utilização, acesso, cópia ou divulgação não autorizada das informações presentes nesta mensagem. As informações contidas nesta mensagem e em seus anexos são de responsabilidade do seu autor, não representando necessariamente ideias, opiniões, pensamentos ou qualquer forma de posicionamento por parte da Macro Assessor de Investimentos Ltda.</p>
                    <p>O investimento em ações é um investimento de risco e rentabilidade passada não é garantia de rentabilidade futura. Na realização de operações com derivativos existe a possibilidade de perdas superiores aos valores investidos, podendo resultar em significativas perdas patrimoniais. Para informações e dúvidas, favor contatar seu Assessor de Investimentos. Para reclamações, favor contatar a Ouvidoria da EQI Corretora no telefone nº 0800-770-0190.</p>
                </div>
            </div>

            <div className="footer-bottom">
                <img src={LogoMacroOnly} alt="Logo Macro Only" style={{ height: '30px' }} />

                <div style={{ fontFamily: 'Roboto, sans-serif', fontSize: 14 }}>
                    <p>2023 – Todos os direitos reservados</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;