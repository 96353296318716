import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './MainCarousel.css';

// Importando os componentes de cada slide
import CommunitySlide from './CommunitySlide';
import CompanySlide from './CompanySlide';
import AgroSlide from './AgroSlide';
import CadForm from 'components/CadForm';

const MainCarousel: React.FC = () => {

  const [isModalCadOpen, setIsModalCadOpen] = useState(false)
  
  return (
    <>
      <Carousel
        showArrows={true}
        autoPlay={true}
        infiniteLoop={true}
        showStatus={false}
        showThumbs={false}
        showIndicators={false} // Desabilita os indicadores
        interval={3000}
        transitionTime={1000} // Evita animações de transição
      >
        <div>
          <CommunitySlide setIsModalCadOpen={setIsModalCadOpen} />
        </div>
        <div>
          <CompanySlide />
        </div>
        <div>
          <AgroSlide />
        </div>
      </Carousel>


      <CadForm
        isOpen={isModalCadOpen}
        onClose={()=>setIsModalCadOpen(false)}
        origin={'Comunidade'}
      />
    </>
  );
};

export default MainCarousel;