import React from 'react';
import './About.css'; // Importa o arquivo CSS para o estilo
import companyLogo from '../../assets/img/png/LogoMacro.png'; // Certifique-se de que o caminho está correto

const About: React.FC = () => {
    const handleLearnMore = () => {
        window.location.href = '/macro'; // Redireciona para a URL desejada
    };

    return (
        <div className="about-container">
            <div className="about-text">
                <h1>Sobre a Macro</h1>

                <div style={{ fontFamily: 'Roboto, sans-serif', fontSize: 20 }}>
                    
                    <p>
                        Nos últimos anos, o mercado financeiro ganhou novos adeptos, novos produtos, novas formas de investir, muito mais conteúdo de aprendizagem e a assessoria financeira se tornou muito importante na caminhada do investidor para o sucesso.
                    </p>
                    <p>
                        O mercado como o conhecíamos se transformou, a bolsa de valores agora é assunto na roda de amigos, tem propaganda na TV e daqui há alguns anos (esperamos) seja até conteúdo nas escolas.
                    </p>
                    <a href="#" onClick={handleLearnMore}>Saiba mais...</a> {/* Redireciona para /macro */}

                </div>
            </div>
            <div className="about-logo">
                <img src={companyLogo} alt="Logo da Empresa" />
            </div>
        </div>
    );
};

export default About;
