// Squad.tsx
import React from 'react';
import './Squad.css'; // Importando o CSS para estilização

import Gabriel from '../../assets/img/png/gabriel_a.png';
import Leandro from '../../assets/img/png/leandro_a.png';
import Alexandre from '../../assets/img/png/alexandre_a.png';
import Vinicius from '../../assets/img/png/vinicius_a.png';

interface Member {
    name: string;
    image: string; // URL da imagem
    role: string; // Função do membro
}

const squadMembers: Member[] = [
    {
        name: 'Gabriel',
        image: Gabriel,
        role: 'Head de Produtos',
    },
    {
        name: 'Alexandre',
        image: Alexandre,
        role: 'Assessor Financeiro',
    }
    /*,
    {
        name: 'Leandro',
        image: Leandro,
        role: 'Head de Produtos Agro',
    },
    {
        name: 'Vinicius',
        image: Vinicius,
        role: 'Head de Produtos Agro',
    },
    */
];

const Squad: React.FC = () => {
    return (
        <div className="squad-container">
            
            <h2>Nossa Equipe</h2>

            <div className="squad-members">
                {squadMembers.map((member, index) => (
                    <div className="squad-member" key={index}>
                        <img src={member.image} alt={member.name} className="member-image" />
                        <h3>{member.name}</h3>
                        <p>{member.role}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Squad;
