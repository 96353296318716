import React from 'react';
import LogoAgro from '../../assets/img/png/LagoMacroAgro.png';
import AgroBackground from '../../assets/img/png/macro_agro_1.png';

const AgroSlide: React.FC = () => {

  const handleNavigateToAgro = () => {
    window.location.href = '/agro'; // Redireciona para a página /macro
  };


  return (
    <div
      style={{
        height: '100%',
        backgroundImage: `url(${AgroBackground})`, // Imagem de fundo
        backgroundSize: 'cover', // Faz a imagem de fundo cobrir todo o slide
        backgroundPosition: 'center', // Centraliza a imagem de fundo
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
    {/* Overlay preto */}
            <div
        style={{
          position: 'absolute', // Posiciona o overlay sobre a imagem
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Cor preta com 50% de opacidade
          zIndex: 1, // Z-index inferior ao texto
        }}
      ></div>

      <img style={{ zIndex: 2 }} src={LogoAgro} alt="Logo Macro Agro" />
      <p style={{ zIndex: 2 }}>O Macro Agro, parte da Macro Assessoria de Investimentos, <br /> apoia produtores rurais e tradings no mercado de commodities.</p>
      <button style={{ zIndex: 2 }} onClick={()=>handleNavigateToAgro()}>Saiba mais</button>
    </div>
  );
};

export default AgroSlide;
