import React, { useEffect, useState } from 'react';
import api from 'services/api';
import logoMacro from '../../assets/img/png/LogoMacro.png'; // Substitua pelo caminho do seu logo
import logoMacroAgro from '../../assets/img/png/LagoMacroAgro.png'; // Substitua pelo caminho do seu logo
import './ProductsInfo.css'; // Importando um arquivo CSS para os estilos

import topImageMacroAgro from '../../assets/img/jpg/background-agro-2.jpg'
import topImageMacroPrivate from '../../assets/img/jpg/background-valuation.jpg'
import topImageMacroGlobal from '../../assets/img/jpg/background-global.jpg'
import topImageMacroInvestimentos from '../../assets/img/jpg/background-investimentos.jpg'
import topImageMacro from '../../assets/img/jpg/background_1.jpg'


// Definindo a interface para o retorno da API
interface PageData {
  title: string;
  content: string; 
}

// Definindo os tipos para os props
interface ProductsInfoProps {
  pageCode: string;  
}

const ProductsInfo: React.FC<ProductsInfoProps> = ({ pageCode }) => {
  const [pageData, setPageData] = useState<PageData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await api.get(`/pages/${pageCode}`);
        setPageData(response.data);
      } catch (err) {
        setError('Erro ao carregar os dados da página.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [pageCode]);

  const formatContent = (content: string) => {
    // Substituindo quebras de linha por <br /> e formatando itálico
    const formattedContent = content
      .replace(/\n/g, '<br />') // Mantendo as quebras de linha
      .replace(/<italic>(.*?)<\/italic>/g, '<i>$1</i>'); // Formatando itálico
    return formattedContent;
  };

  if (loading) return <p>Carregando...</p>;
  if (error) return <p>{error}</p>;


  const imageMap: { [key: string]: string } = {
    'Macro Agro': topImageMacroAgro,
    'Macro Private': topImageMacroPrivate,
    'Macro Global': topImageMacroGlobal, 
    'Macro Investimentos': topImageMacroInvestimentos,
    'Macro': topImageMacro, 
  };

  const selectedImage = imageMap[pageData!.title] || topImageMacro

  return (
    <div className="container">
      {pageData ? (
        <>
          <div className="content">

            <div style={{ 
              position: 'relative', 
              width: '100%', 
              height: '200px', // Definindo uma altura fixa para o contêiner
              overflow: 'hidden', // Esconde o excesso da imagem que ultrapassar o contêiner
              borderRadius: 10
            }}>
              
              <img 
                src={selectedImage} 
                style={{ 
                  width: '100%', 
                  height: '100%', // A imagem irá ocupar toda a altura do contêiner
                  position: 'absolute', 
                  left: 0, 
                  top: 0, 
                  objectFit: 'cover' // A imagem mantém a proporção e cobre todo o contêiner
                }} 
                alt="Descrição da imagem" // Adicione uma descrição para acessibilidade
              />

              {/* Logo posicionado acima da imagem */}
              <img 
                src={pageData.title === "Macro Agro" ? logoMacroAgro : logoMacro} 
                alt="Macro Assessoria" 
                className={ pageData.title === "Macro Agro" ? 'logoLP' : 'logoLP2'}
                style={{ 
                  position: 'absolute', // Torna o logo absoluto em relação ao contêiner pai
                  top: pageData.title === "Macro Agro" ? '70px' : '65px', // Distância do topo do contêiner (ajuste conforme necessário)
                  left: '50%', // Centraliza horizontalmente
                  transform: 'translateX(-50%)', // Ajusta a posição para centralizar corretamente
                  zIndex: 1 // Garante que o logo fique acima da imagem
                }} 
              />
            </div>

            <div 
              style={{ 
                display: pageData.title === "Macro Agro" ? 'none' : 'flex', 
                width: '100%',
                height: '100px', // Defina uma altura fixa para o contêiner
                justifyContent: 'center', // Centraliza horizontalmente
                alignItems: 'center', // Centraliza verticalmente
                marginTop: pageData.title === "Macro Agro" ? 0 : 30 // Espaçamento superior condicional
              }}>
              <h1 
                style={{ 
                  fontSize: 30, 
                  marginBottom: 50,
                  margin: 0 // Removendo margens padrão para melhor centralização
                }}>
                {pageData.title}
              </h1>
            </div>

            <div
              style={{ 
                fontFamily: 'Roboto, sans-serif', 
                fontSize: 20, 
                textAlign: 'justify',
                marginTop: pageData.title === "Macro Agro" ? 50 : 0
              }}
              dangerouslySetInnerHTML={{ __html: formatContent(pageData.content) }}
            />
          </div>
        </>
      ) : (
        <p>Nenhum dado encontrado para esta página.</p>
      )}
    </div>
  );
};


export default ProductsInfo;
