// utils/encrypt.ts
import CryptoJS from 'crypto-js';

// Função para criptografar um valor
export const encryptData = (value: string): string => {
  const encryptionKey = process.env.REACT_APP_KEY_CRIPT;

  if (!encryptionKey) {
    throw new Error("A chave de criptografia não foi encontrada. Verifique a variável REACT_APP_KEY_CRIPT no .env.");
  }

  return CryptoJS.AES.encrypt(value, encryptionKey).toString();
};

// Função para descriptografar um valor
export const decryptData = (encryptedValue: string): string => {
  const encryptionKey = process.env.REACT_APP_KEY_CRIPT;

  if (!encryptionKey) {
    throw new Error("A chave de descriptografia não foi encontrada. Verifique a variável REACT_APP_KEY_CRIPT no .env.");
  }

  const bytes = CryptoJS.AES.decrypt(encryptedValue, encryptionKey);
  return bytes.toString(CryptoJS.enc.Utf8);
};
