import React, { useEffect, useState } from 'react';
import {
  DashboardOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  TeamOutlined,
  BookOutlined,
  RubyOutlined,
  SelectOutlined,
  LinkOutlined
} from '@ant-design/icons';
import { Avatar, Button, Layout, Menu, Modal, theme } from 'antd';
import LogoMacro from '../../../assets/img/png/LogoMacro.png';
import AvatarImg from '../../../assets/img/png/finiteLogo.png';
import UserController from '../UserController';
import ReportController from '../ReportController';
import DashboardController from '../DashboardController';
import LeadsController from '../LeadsController';

const { Header, Sider, Content } = Layout;

const Main: React.FC = () => {
  
  const [collapsed, setCollapsed] = useState(false);
  const [currentPage, setCurrentPage] = useState('dashboard'); // Estado para a página atual

  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(()=>{
    const tkn = localStorage.getItem('tkn')
    const n = localStorage.getItem('n')
    if (tkn === null || tkn === '' || n === null || n === '' || n !== 'A' ){
        window.location.href = '/'
    }
  })

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const handleLogout = () => {
    localStorage.removeItem('tkn');
    window.location.href = '/login'; // Redireciona para a tela de login após logout
  };

  const handleNavigation = (path: string) => {
    setCurrentPage(path); // Atualiza a página atual
  };

  const renderContent = () => {
    switch (currentPage) {
      case 'dashboard':
        return <DashboardController />;
      case 'reports':
        return <ReportController />;
      case 'leads':
        return <LeadsController />;        
      case 'users':
        return <UserController />;
      default:
        return <DashboardController />;
    }
  };

  const handleGoToSite = () =>{
    window.location.href = '/';
  }

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Avatar 
        src={AvatarImg}
        style={{ 
          position: 'absolute',
          right: 20,
          backgroundColor: '#c6c6c6',
          width: 40,
          height: 40,
          marginTop: 10
        }}
      />

      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
      >
        <div className="logo" style={{ padding: 16, textAlign: 'center' }}>
          <img 
            src={LogoMacro} 
            alt="Macro" 
            style={{ width: collapsed ? '100%' : '80%', margin: '0 auto' }} 
            onClick={()=>handleGoToSite}
          />
        </div>

        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={['1']}
        >
          <Menu.Item key="1" icon={<DashboardOutlined />} onClick={() => handleNavigation('dashboard')}>
            Dashboard
          </Menu.Item>
          <Menu.Item key="2" icon={<RubyOutlined />} onClick={() => handleNavigation('leads')}>
            Leads
          </Menu.Item>
          <Menu.Item key="3" icon={<BookOutlined />} onClick={() => handleNavigation('reports')}>
            Relatórios
          </Menu.Item>
          <Menu.Item key="4" icon={<LinkOutlined />} onClick={() => setIsModalVisible(true)}>
            Link Comunidade
          </Menu.Item>
          <Menu.Item key="5" icon={<TeamOutlined />} onClick={() => handleNavigation('users')}>
            Usuários
          </Menu.Item>
          <Menu.Item key="6" icon={<SelectOutlined />} onClick={handleGoToSite}>
            Voltar ao Site
          </Menu.Item>
          <Menu.Item key="7" icon={<LogoutOutlined />} onClick={handleLogout}>
            Sair
          </Menu.Item>
        </Menu>

      </Sider>

      <Layout>
        <Header style={{ padding: 0, background: colorBgContainer }}>
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: '16px',
              width: 34,
              height: 34,
              position: 'absolute',
              top: 10,
              left: collapsed ? 95 : 215,
              backgroundColor: '#000'
            }}
          />
        </Header>
        <Content
          style={{
            margin: '24px 16px',
            padding: 24,
            minHeight: 280,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          {renderContent()} {/* Renderiza o conteúdo da página atual */}
        </Content>
      </Layout>

      <Modal
        title="Link da Comunidade para Distribuição"
        visible={isModalVisible}
        onCancel={()=>setIsModalVisible(false)}
        footer={[
          <Button key="close" onClick={()=>setIsModalVisible(false)}>
            Fechar
          </Button>,
        ]}
      >
        <p>https://macroai.com.br/community</p>
      </Modal>
      
    </Layout>
  );
};

export default Main;
