import React, { useState } from 'react';
import './AboutEqi.css'; // Importa o arquivo CSS para o estilo
import companyLogo from '../../assets/img/png/eqi_logo.png'; // Certifique-se de que o caminho está correto

const AboutEqi: React.FC = () => {
    const [isModalOpen, setIsModalOpen] = useState(false); // Estado para controlar o modal

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div className="about-container-eqi">
            <div className="about-logo-eqi">
                <img src={companyLogo} alt="Logo da Empresa" />
            </div>
            <div className="about-text-eqi">
                <h1>Sobre a EQI</h1>

                <div style={{ fontFamily: 'Roboto, sans-serif', fontSize: 20 }}>
                    <p>
                        Uma das maiores corretoras do país, com mais de 70 mil clientes e mais de R$ 33 bilhões em ativos sob custódia. A EQI tem como objetivo dar autonomia aos clientes para que possam investir em suas escolhas.
                    </p>
                    <a href="https://eqi.com.br" target='_blank'>Saiba mais...</a>
                </div>
            </div>
        </div>
    );
};

export default AboutEqi;
