export async function containsRepeatedNumbers(verifyPhone: string){

    const cleaned = verifyPhone.replace(/\D/g, ''); // Remove tudo que não é número

    // Verifica se o número é uma sequência repetitiva (ex: 111111..., 22222...)
    const isSequential = /^(\d)\1+$/.test(cleaned);
    if (isSequential) return false;

    for (let i = 1; i <= 9; i++) {
        const repeatedNumber = String(i).repeat(11); // Cria a sequência como '11111111111', '22222222222', etc.
        if (verifyPhone === repeatedNumber) {
            return true
        }
    }
    return false
};