import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL ||  'http://localhost:3333/api/'
});

/*
const apiOpenIa = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL_BOT
});
*/

// Gera um UUID para o ID
const guid = uuidv4();

export const postMessageOpenIa = async (newMessage: string) => {
  // Cria o objeto de dados a ser enviado
  let data = {
    id: guid,
    mensagem: newMessage
  };

  try {
    // Faz a requisição POST
    const response = await api.post('mensagem', data, {
      headers: {
        'Content-Type': 'application/json'
      },
      maxBodyLength: Infinity // Configuração para permitir grandes volumes de dados, se necessário
    });
    
    // Retorna a resposta da API
    return response.data;
  } catch (error) {
    console.error('Erro ao enviar a mensagem:', error);
    throw error;
  }
};

export default api;
