import React, { useState, useEffect, useRef } from 'react';
import 'react-chat-widget/lib/styles.css';
import avatarUser from '../../assets/img/svg/user-avatar.svg';
import closeButton from '../../assets/img/svg/close.svg';
import finiteLogo from '../../assets/img/png/finiteLogo.png';
import './AIChat.css';
import { postMessageOpenIa } from '../../services/api';

// Componente de Mensagem
interface MessageProps {
  text: string;
  timestamp: string;
  isBot?: boolean;
}

const Message: React.FC<MessageProps> = ({ text, timestamp, isBot }) => (
  <div className={`message ${isBot ? 'bot' : 'user'}`}>
    {isBot && (
      <img
        src={finiteLogo}
        alt="Bot Avatar"
        className="avatar"
      />
    )}
    <div className="message-content">
      <div dangerouslySetInnerHTML={{ __html: text }} />
      <div className="message-timestamp">{timestamp}</div>
    </div>
    {!isBot && (
      <img
        src={avatarUser}
        alt="User Avatar"
        className="avatar"
      />
    )}
  </div>
);

const LoadingDots: React.FC = () => (
  <div className="loading-dots">
    <div className="dot"></div>
    <div className="dot"></div>
    <div className="dot"></div>
  </div>
);

const formatMessage = (text: string): string => {
  return text
    .replace(/\*\*(.*?)\*\*/g, '<b>$1</b>') // Negrito
    .replace(/### (.*?)\n/g, '<h3>$1</h3>') // Cabeçalhos
    .replace(/(\d+) - (.*?)(?=\n|$)/g, '<br/><br/>$1 - $2') // Números seguidos de texto com espaçamento
    .replace(/\n/g, '<br/>'); // Quebras de linha
};

const AIChat: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState<MessageProps[]>([]);
  const [inputText, setInputText] = useState('');
  const [showChat, setShowChat] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement | null>(null); // Ref para o final da área de mensagens

  // Exibe mensagem inicial quando o chat é aberto
  useEffect(() => {
    if (!messages.length && showChat) {
      handleBotMessage('Olá, em que posso ajudá-lo?');
    }
  }, [showChat]);

  // Scroll automático para o final quando uma nova mensagem é adicionada
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const handleBotMessage = (message: string) => {
    setMessages(prevMessages => [
      ...prevMessages,
      { text: message, timestamp: new Date().toLocaleTimeString(), isBot: true },
    ]);
  };

  const telefoneRegex = /^\d{2}-\d{5}-\d{4}$/;

  const handleNewUserMessage = async (newMessage: string) => {
    setLoading(true);
    setMessages(prevMessages => [
      ...prevMessages,
      { text: newMessage, timestamp: new Date().toLocaleTimeString(), isBot: false },
    ]);
  
    // Verificar se o usuário quer falar com um consultor
    if (newMessage.toLowerCase() === 'falar com assessor') {
      await postMessageOpenIa('falar com assessor');
      handleBotMessage('Por favor, informe seu número de telefone no formato xx-xxxxx-xxxx:');
      setLoading(false);
      return;
    }
  
    // Verificar se a mensagem é um número de telefone e validar
    if (telefoneRegex.test(newMessage)) {
      handleBotMessage('Um assessor entrará em contato em breve.');
      const message = `assessor|${newMessage}`;
      console.log(message);
      await postMessageOpenIa(message);

    } else if (newMessage.match(/^\d{2}-/)) {
      // Se a mensagem começar com "xx-", mas for inválida
      handleBotMessage('Número inválido. Certifique-se de que está no formato correto: xx-xxxxx-xxxx.');
    } else {
      try {
        const response = await postMessageOpenIa(newMessage);
        const formattedResponse = formatMessage(response.resposta.resposta);
        setMessages(prevMessages => [
          ...prevMessages,
          { text: formattedResponse, timestamp: new Date().toLocaleTimeString(), isBot: true },
        ]);
      } catch (error) {
        console.error('Erro ao enviar mensagem para Finite:', error);
        setMessages(prevMessages => [
          ...prevMessages,
          { text: 'Desculpe, houve um problema ao processar sua solicitação.', timestamp: new Date().toLocaleTimeString(), isBot: true },
        ]);
      }
    }
  
    setLoading(false);
  };
  

  const handleSendMessage = () => {
    if (inputText.trim()) {
      handleNewUserMessage(inputText);
      setInputText('');
    }
  };

  return (
    <>
      <div 
        className="chat-container" 
        style={{ display: showChat ? 'flex' : 'none' }}>
        <div className="chat-header">
          <h2 className="chat-header-text">Finite</h2>
          <img 
            src={closeButton} 
            style={{ position: 'absolute', top: 10, width: 22, right: 10, cursor: 'pointer' }} 
            alt="Fechar chat"
            onClick={() => setShowChat(false)}
          />
        </div>
        <div className="chat-body">
          <div className="message-list" style={{ fontFamily: 'Roboto, sans-serif' }}>
            {messages.map((message, index) => (
              <Message
                key={index}
                text={message.text}
                timestamp={message.timestamp}
                isBot={message.isBot}
              />
            ))}
            {loading && <LoadingDots />}
            <div ref={messagesEndRef} /> {/* Referência para o final da lista de mensagens */}
          </div>
        </div>
        <div className="chat-footer">
          <input
            type="text"
            placeholder="Digite sua mensagem..."
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleSendMessage();
              }
            }}
          />
          <button onClick={handleSendMessage}>Enviar</button>
        </div>
      </div>

      <div>
        <img
          src={finiteLogo}
          alt="Bot Avatar"
          onClick={() => setShowChat(!showChat)}
          style={{
            width: 60,
            borderRadius: '50%',
            backgroundColor: '#c6c6c6',
            padding: 2,
            position: 'fixed',
            bottom: 27,
            right: 35,
            cursor: 'pointer',
            zIndex: 100
          }}
        />
      </div>
    </>
  );
};

export default AIChat;
