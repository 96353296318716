import React from 'react';
import './Modal.css'; // Importa os estilos para o modal

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    title: string;
    children: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, title, children }) => {
    if (!isOpen) return null; // Não renderiza o modal se não estiver aberto

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                {/* <h2 style={{ marginBottom: 40 }}>{title}</h2> */}
                <div>{children}</div>
                <button style={{ cursor: 'pointer' }} onClick={onClose}>Fechar</button>
            </div>
        </div>
    );
};

export default Modal;
