import { ContactFormInterface } from "interfaces/contactFormInterface";
import api from "services/api";


export const sendContactForm = async (data: ContactFormInterface) => {

    try {
      // Faz a requisição POST
      const response = await api.post('/users', data, {
        headers: {
          'Content-Type': 'application/json'
        },
        maxBodyLength: Infinity
      });
      
      // Exibe a resposta
      console.log(JSON.stringify(response.data));
      
      // Retorna a resposta da API
      return response.data;

    } catch (error) {
      console.error('Erro ao enviar a mensagem:', error);
      throw error;
    }
  };