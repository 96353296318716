import React, { useEffect, useState } from "react";
import { Modal, Button, Table, Form, Input, Select, message, Divider } from "antd";
import api from "services/api"; // Certifique-se de que a API está corretamente importada

const { Option } = Select;

interface User {
  key: string;
  fullName: string;
  email: string;
  phone: string;
  accessLevel: string; // A = Administrador, L = Lead, C = Cliente
}

const buttonStyleAdd = {
    color: '#fff',
    marginLeft: 10,
    fontFamily: 'Arial, sans-serif', // Adicionando a fonte desejada
    fontSize: '16px', // Definindo o tamanho da fonte
    fontWeight: 'normal', // Definindo o peso da fonte
    height: 35
  };

const buttonStyleCancel = {
    color: '#fff',
    marginLeft: 10,
    fontFamily: 'Arial, sans-serif', // Adicionando a fonte desejada
    fontSize: '16px', // Definindo o tamanho da fonte
    fontWeight: 'normal', // Definindo o peso da fonte
    height: 35
};

const UsersController = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [editingUser, setEditingUser] = useState<User | null>(null);
  const [form] = Form.useForm();

  // Função para buscar usuários
  const fetchUsers = async () => {
    try {
      const response = await api.get("/users");
      setUsers(response.data);
    } catch (error) {
      console.error("Erro ao buscar usuários:", error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  // Função para abrir o modal
  const openModal = (user?: User) => {
    if (user) {
      setEditingUser(user);
      form.setFieldsValue(user); // Preencher o formulário com os dados do usuário
    } else {
      setEditingUser(null);
      form.resetFields(); // Limpar o formulário
    }
    setModalVisible(true);
  };

  // Função para salvar o usuário
  const handleSubmit = async (values: User) => {
    try {
      if (editingUser) {
        await api.put(`/users/${editingUser.key}`, values); // Editar usuário existente
        message.success("Usuário atualizado com sucesso!");
      } else {

          // Prepare os dados para a API
        const dataToSubmit = {
            ...values,
            origin: 'Adm',  // Adicionando o campo 'origin'
            lead: false,    // Adicionando o campo 'lead'
        };
        
        await api.post("/users", dataToSubmit); // Adicionar novo usuário
        message.success("Usuário adicionado com sucesso!");
      }
      setModalVisible(false);
      fetchUsers(); // Atualizar lista de usuários
    } catch (error) {
      console.error("Erro ao salvar o usuário:", error);
      message.error("Erro ao salvar o usuário.");
    }
  };

  // Função para excluir o usuário
  const handleDelete = async (key: string) => {
    try {
      await api.delete(`/users/${key}`);
      message.success("Usuário excluído com sucesso!");
      fetchUsers(); // Atualizar lista de usuários
    } catch (error) {
      console.error("Erro ao excluir o usuário:", error);
      message.error("Erro ao excluir o usuário.");
    }
  };

  const columns = [
    {
      title: "Nome",
      dataIndex: "fullName",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Telefone",
      dataIndex: "phone",
    },
    {
      title: "Nível de Acesso",
      dataIndex: "accessLevel",
      render: (accessLevel: string) => {
        switch (accessLevel) {
          case "A":
            return "Administrador";
          case "L":
            return "Lead";
          case "C":
            return "Cliente";
          default:
            return "Desconhecido";
        }
      },
    },
    {
      title: "Ação",
      render: (text: any, user: User) => (
        <>
          <Button style={{ backgroundColor: '#4975ae' }} onClick={() => openModal(user)}>Editar</Button>
          <Button onClick={() => handleDelete(user.key)} style={{ marginLeft: 10 }}>
            Excluir
          </Button>
        </>
      ),
    },
];

  return (
    <div className="adm-report-file-upload-container">
      <h2 style={{ textAlign: 'left', fontStyle: 'italic' }}>Gerenciar Usuários</h2>
      <Button
        style={{ backgroundColor: '#323e56', color: '#fff', position: 'absolute', marginTop: -40, right: 60 }}
        onClick={() => openModal()}
      >
        Adicionar Usuário
      </Button>

      <Table
        dataSource={users}
        columns={columns}
        rowKey="key" // Use uma chave única para cada linha
        pagination={{ pageSize: 5 }} // Personalize a paginação conforme necessário
      />

    <Modal
        title={<span>{editingUser ? "Editar Usuário" : "Adicionar Usuário"}</span>}
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
        centered // Centraliza o modal
        >
        <Form form={form} onFinish={handleSubmit} layout="vertical">
          <Form.Item
            label="Nome"
            name="fullName"
            rules={[{ required: true, message: "Por favor, insira o nome!" }]}
            style={{ marginBottom: -50, marginTop: 30}}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: "Por favor, insira o email!" }]}
            style={{ marginBottom: -50}}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Telefone"
            name="phone"
            rules={[{ required: true, message: "Por favor, insira o telefone!" }]}
            style={{ marginBottom: -50}}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Nível de Acesso"
            name="accessLevel"
            rules={[{ required: true, message: "Por favor, selecione o nível de acesso!" }]}
            style={{ marginBottom: -30}}
          >
            <Select style={{ height: 40 }}> 
              <Option value="A">Administrador</Option>
              <Option value="L">Lead</Option>
              <Option value="C">Cliente</Option>
            </Select>
          </Form.Item>

          <Divider />

          <Form.Item style={{ marginBottom: -50, textAlign: 'right' }}>
            
            <Button
              style={buttonStyleCancel}
              onClick={() => {
                setModalVisible(false);
                form.resetFields(); // Limpar o formulário ao cancelar
              }}
            >
              Cancelar
            </Button>

            <Button type="primary" htmlType="submit" style={buttonStyleAdd}>
              {editingUser ? "Salvar" : "Adicionar"}
            </Button>

          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default UsersController;
