import React, { useEffect, useState } from "react";
import logo from '../../assets/img/png/LogoMacro.png';
import { ContactFormInterface } from "interfaces/contactFormInterface";
import { sendContactForm } from 'services/contactForm';
import Modal from "components/Modal";
import backgroundImage from '../../assets/img/jpg/background-valuation.jpg'; // Importe a imagem
import { decryptData } from "utils/cript";
import api from "services/api";
import { containsRepeatedNumbers } from "utils/validator";

interface Report {
    key: string;
    title: string;
    fileName: string;
    url: string; // O link seguro já virá no payload
}

const formatPhoneNumber = (value: string) => {
    const cleaned = value.replace(/\D/g, ''); // Remove tudo que não é número

    // Aplica a máscara dependendo do tamanho do número
    if (cleaned.length <= 10) {
        return cleaned.replace(/(\d{2})(\d{4})(\d{0,4})/, '($1) $2-$3');
    } else {
        return cleaned.replace(/(\d{2})(\d{5})(\d{0,4})/, '($1) $2-$3');
    }
};


const Download = () =>{

    const { pathname } = window.location;
    const key = pathname.split('/')[2];

    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [error, setError] = useState('');
    
    const [loading, setLoading] = useState(false)

    const [reportSelected, setReportselected] = useState<Report>()

    const [modalVisible, setModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState({
        title: '',
        message: ''
    });

    useEffect(() => {

        setEmail('');
        setName('');
        setPhone('');
        setError('');

        const fetchData = async () => {
            await getDataReport()
        };

        fetchData()
    }, []); 

    const getDataReport = async () =>{
        try {
            setLoading(true);
            const response = await api.get(`/download/${key}`);
            setReportselected(response.data);
          } catch (err) {
            setError('Erro ao carregar os dados da página.');
          } finally {
            setLoading(false);
          }
    }
    
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        // Validação do telefone
        let verifyPhone = phone.replaceAll('-', '').replace('(', '').replace(')', '').replace(' ', '')
        
        if (verifyPhone.length !== 11 || await containsRepeatedNumbers(verifyPhone) ) {
            setModalContent({
                title: 'Erro!',
                message: 'Telefone incompleto. Por favor, preencha corretamente.'
            });
            setModalVisible(true);
            setError('Telefone incompleto')
            return;
        }

        setLoading(true); // Inicia o carregamento

        try {
            const newContact: ContactFormInterface = {
                fullName: name,
                email: email,
                phone: `55${verifyPhone}`,
                // phone: verifyPhone,
                origin: "relatorio",
                accessLevel: "L",
                lead: true
            };
            await sendContactForm(newContact);

            await handleDownload(decryptData(reportSelected?.url!), reportSelected?.fileName!)
            
            // Se o envio for bem-sucedido
            setModalContent({
                title: '',
                message: 'Agradecemos seu cadastro, em breve nossa equipe entrará em contato.'
            });
            
            setModalVisible(true);

        } catch (error) {

            await handleDownload(decryptData(reportSelected?.url!), reportSelected?.fileName!)

            // Se o envio for bem-sucedido
            setModalContent({
                title: '',
                message: 'Seu download iniciará em breve!'
            });
            
            setModalVisible(true);
            
        } finally {
            
            setError('');
            setLoading(false); // Finaliza o carregamento, seja sucesso ou erro
        }
    };

    const handleModalClose = async () => {        
        setModalVisible(false);
        if (error === ''){
            window.location.href = '/';
        }
    };


    const handleDownload = async (url: string, fileName: string) => {

        try {
            // Fazendo a solicitação para a URL do arquivo
            const response = await fetch(decryptData(url), {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/octet-stream',
                },
            });
    
            // Verifica se a resposta foi bem-sucedida
            if (!response.ok) {
                throw new Error('Erro ao baixar o arquivo.');
            }
    
            const blob = await response.blob(); // Cria um blob com o conteúdo do arquivo
            const link = document.createElement('a'); // Cria um link temporário
            link.href = window.URL.createObjectURL(blob); // Cria um URL para o blob
            link.download = fileName; // Define o nome do arquivo a ser baixado
            document.body.appendChild(link); // Adiciona o link ao DOM
            link.click(); // Simula o clique no link para forçar o download
            document.body.removeChild(link); // Remove o link do DOM
            window.URL.revokeObjectURL(link.href); // Libera a URL do blob

        } catch (error) {
            console.error("Erro ao baixar o arquivo:", error);
        }

        window.location.href = '/';
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const formattedPhone = formatPhoneNumber(e.target.value);
        setPhone(formattedPhone);    
    };
    
    return(
        <div className="base-modal">

            <div
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    height: '100%',
                    width: '100%',
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    zIndex: -1,
                }}
            />

            {/* Camada preta semi-transparente */}
            <div
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    height: '100%',
                    width: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Fundo preto com 50% de opacidade
                    zIndex: -1,
                }}
            />

            <div className="base-modal-content">
                <img src={logo} alt="Logo Macro" width={150} style={{ marginTop: 20, marginBottom: 30 }} />
                
                <p style={{ color: '#fff', marginBottom: 40 }}>
                    {reportSelected?.title}
                </p>

                <form onSubmit={handleSubmit}>
                    <div>
                        <input 
                            type="text" 
                            value={name} 
                            onChange={(e) => setName(e.target.value)} 
                            required 
                            placeholder={'Nome'}
                            style={{ width: '93%'}}
                        />
                    </div>
                    <div>
                        <input 
                            type="phone" 
                            value={phone} 
                            placeholder={'(00) 00000-0000'}
                            // onChange={(e) => setPhone(e.target.value)} 
                            required 
                            style={{ width: '93%'}}
                            onChange={(e) => handleChange(e)}
                            maxLength={15}
                        />
                    </div>
                    <div>
                        <input 
                            type="email" 
                            value={email} 
                            onChange={(e) => setEmail(e.target.value)} 
                            required 
                            placeholder={'e-mail'}
                            style={{ width: '92%'}}
                        />
                    </div>

                    {error && <p className="error">{error}</p>}

                    <div className="button-container" style={{ marginTop: 30, display: 'flex', alignItems: 'center', marginLeft: 20 }}>
                        <button 
                            style={{ marginLeft: -10, height: 40, display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', fontSize: 15 }}
                            type="submit"
                        >
                            {loading ? <div className="spinner"></div> : 'Realizar Download'}
                        </button>
                    </div>
                            
                </form>
            </div>

            <Modal
                isOpen={modalVisible}
                onClose={() => handleModalClose()}
                title={modalContent.title}
            >
                <p>{modalContent.message}</p>
            </Modal>
        </div>
    )

}

export default Download