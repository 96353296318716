import React, { useEffect, useState } from 'react';
import { Select, Row, Col, Card } from 'antd';
import ReactPlayer from 'react-player/youtube';
import api from 'services/api';
import topImage from '../../assets/img/jpg/backgroundVideo.jpg'

const { Option } = Select;

interface Category {
  key: string;
  name: string;
}

interface Video {
  key: string;
  title: string;
  url: string;
}

const VideoGallery: React.FC = () => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [videos, setVideos] = useState<Video[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);

  useEffect(() => {
    api.get('/videocategories').then((response) => {
      setCategories(response.data);
    });
  }, []);

  useEffect(() => {
    if (selectedCategory) {
      api.get(`/videos/${selectedCategory}`).then((response) => {
        setVideos(response.data);
      });
    }
  }, [selectedCategory]);

  return (
    <div style={{ padding: 40, margin: '0 auto', marginTop: 60 }}>
      
      <div style={{ 
        position: 'relative', 
        width: '100%', 
        height: '200px', // Definindo uma altura fixa para o contêiner
        overflow: 'hidden', // Esconde o excesso da imagem que ultrapassar o contêiner
        borderRadius: 10
      }}>

        {/* Título sobre a imagem */}
        <h2 style={{ 
          position: 'absolute', // Posiciona o título sobre a imagem
          top: '37%', // Centraliza verticalmente
          left: '50%', // Centraliza horizontalmente
          transform: 'translate(-50%, -50%)', // Move para o centro
          zIndex: 2, // Garante que o título fique acima da imagem
          color: '#fff', // Altere a cor conforme necessário para garantir visibilidade
          textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)', // Sombra do texto para melhor legibilidade
          fontSize: 30
        }}>
          Galeria de Vídeos
        </h2>
        
        <img 
          src={topImage} 
          style={{ 
            width: '100%', 
            height: '100%', // A imagem irá ocupar toda a altura do contêiner
            position: 'absolute', 
            left: 0, 
            top: 0, 
            zIndex: 1,
            objectFit: 'cover' // A imagem mantém a proporção e cobre todo o contêiner
          }} 
          alt="Descrição da imagem" // Adicione uma descrição para acessibilidade
        />
      </div>

      {/* Dropdown para selecionar categoria */}
      <Select
        placeholder="Selecione uma categoria"
        style={{ width: 300, marginBottom: 20, marginTop: 20 }}
        onChange={(value) => setSelectedCategory(value)}
      >
        {categories.map((category) => (
          <Option key={category.key} value={category.key}>
            {category.name}
          </Option>
        ))}
      </Select>

      {/* Lista Responsiva de Vídeos usando Row e Col */}
      <Row gutter={[16, 16]}>
        {videos.map((video) => (

          <Col xs={24} sm={12} md={8} lg={6} key={video.key}>
            <Card
              title={video.title}
              hoverable
              style={{
                borderRadius: 8,
                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
              }}
            >

              <ReactPlayer
                    url={video.url}
                    controls={false} // Desabilita controles do YouTube
                    width="100%"
                    height="250px"
                    playing={false}
                    light={true}
                    config={{
                      embedOptions: {
                        youtube: {
                          playerVars: { 
                            disablekb: 1, // Desabilita o teclado
                            modestbranding: 1, // Remove logo do YouTube
                            rel: 0, // Evita vídeos sugeridos ao fim
                            
                          },
                        },
                      }
                    }}
                  />
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default VideoGallery;
