import React, { useState, useCallback, useEffect } from 'react';
import AWS, { S3 } from 'aws-sdk';
import api from 'services/api';
import './ReportController.css';
import { Table, Modal, Button, Upload, Form, Input, message, Divider } from 'antd';
import WordIcon from '../../../assets/img/png/word.png';
import ExcelIcon from '../../../assets/img/png/excel.png';
import PdfIcon from '../../../assets/img/png/pdf.png';
import { decryptData, encryptData } from 'utils/cript';
import { UploadOutlined, LinkOutlined } from '@ant-design/icons';

// Estilos de botão
const buttonStyleAdd = {
  color: '#fff',
  marginLeft: 10,
  fontFamily: 'Arial, sans-serif',
  fontSize: '16px',
  fontWeight: 'normal',
  height: 35,
};

const buttonStyleCancel = {
  color: '#fff',
  marginLeft: 10,
  fontFamily: 'Arial, sans-serif',
  fontSize: '16px',
  fontWeight: 'normal',
  height: 35,
};

// Configuração do AWS S3
const s3 = new S3({
  region: process.env.REACT_APP_AWS_REGION,
  credentials: new AWS.Credentials(
    process.env.REACT_APP_AWS_ACCESS_KEY_ID || '',
    process.env.REACT_APP_AWS_SECRET_ACCESS_KEY || ''
  ),
});

const BUCKET_NAME = process.env.REACT_APP_AWS_BUCKET_NAME || '';

// Mapeamento de extensões para tipos MIME
const mimeTypes: { [key: string]: string } = {
  pdf: 'application/pdf',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
};

// Função para retornar o ícone baseado no tipo de arquivo
const getFileIcon = (fileType: string) => {
  switch (fileType) {
    case 'doc':
    case 'docx':
      return <img src={WordIcon} alt="Word Icon" className="file-icon" width={30} />;
    case 'xls':
    case 'xlsx':
      return <img src={ExcelIcon} alt="Excel Icon" className="file-icon" width={30} />;
    case 'pdf':
      return <img src={PdfIcon} alt="PDF Icon" className="file-icon" width={30} />;
    default:
      return null;
  }
};

const ReportController: React.FC = () => {
  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [reports, setReports] = useState<any[]>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);


  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentKey, setCurrentKey] = useState('');

  const handleLinkClick = (key: string) => {
    setCurrentKey(key);
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setCurrentKey('');
  };

  const handleFileChange = (info: any) => {

    const file = info.file;

    console.log(file)
    if (file) {
      setSelectedFile(file);
    }
  };

  const handleUpload = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      // event.preventDefault(); // Impede o comportamento padrão do formulário

      if (!selectedFile) {
        message.error('Por favor, selecione um arquivo para fazer o upload.');
        return;
      }

      const fileName = selectedFile.name;
      const fileParts = fileName.split('.');
      const fileExtension = fileParts.length > 1 ? fileParts.pop()?.toLowerCase() : '';
      const fileType = mimeTypes[fileExtension || ''] || '';

      if (!fileType) {
        message.error('Tipo de arquivo não suportado. Por favor, envie um arquivo .doc, .xlsx ou .pdf.');
        return;
      }

      const params: AWS.S3.PutObjectRequest = {
        Bucket: BUCKET_NAME,
        Key: `${fileName}`,
        Body: selectedFile,
        ContentType: fileType,
      };

      try {
        setLoading(true);
        const data = await s3.upload(params).promise();
        const fileUrl = data.Location;

        const fileData = {
          title: title,
          description: description,
          fileName: fileName,
          fileType: fileExtension,
          url: encryptData(fileUrl),
        };

        const response = await api.post('/reports', fileData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (!response.data) {
          throw new Error('Erro ao enviar os dados para a API');
        }

        message.success(`Arquivo ${fileName} enviado com sucesso!`);
        setTitle('');
        setDescription('');
        setSelectedFile(null);
        setModalOpen(false);
        await fetchReports();
      } catch (error) {
        console.error('Erro ao fazer o upload:', error);
        message.error('Erro ao fazer o upload do arquivo. Tente novamente.');
      } finally {
        setLoading(false);
      }
    },
    [selectedFile, title, description]
  );

  const fetchReports = useCallback(async () => {
    try {
      const response = await api.get('/reports');
      setReports(response.data);
    } catch (error) {
      console.error('Erro ao buscar relatórios:', error);
      message.error('Erro ao buscar relatórios. Tente novamente.');
    }
  }, []);

  const toggleReportStatus = async (key: string, active: boolean | null) => {
    try {
      await api.post(`/reports/${active ? 'disable' : 'enable'}/${key}`);
      await fetchReports();
    } catch (error) {
      console.error(`Erro ao ${active ? 'desativar' : 'ativar'} o relatório:`, error);
      message.error(`Erro ao ${active ? 'desativar' : 'ativar'} o relatório.`);
    }
  };

  useEffect(() => {
    fetchReports();
  }, [fetchReports]);

  const columns = [
    {
      title: 'Tipo',
      dataIndex: 'fileType',
      render: (fileType: string) => getFileIcon(fileType),
    },
    {
      title: 'Título',
      dataIndex: 'title',
    },
    {
      title: 'Descrição',
      dataIndex: 'description',
    },
    {
      title: 'Arquivo',
      dataIndex: 'fileName',
    },
    {
      title: 'Link',
      dataIndex: 'key',
      render: (key: string) => (
        <Button type="link" onClick={() => handleLinkClick(key)}>
          <LinkOutlined />
        </Button>
      ),
    },
    {
      title: 'URL',
      dataIndex: 'url',
      render: (url: string) => (
        <a href={decryptData(url)} target="_blank" rel="noopener noreferrer">Download</a>
      ),
    },
    {
      title: 'Ação',
      render: (text: any, report: any) => (
        <Button
          onClick={() => toggleReportStatus(report.key, report.deletedAt === null)}
          style={{ backgroundColor: report.deletedAt === null ? '#d1603b' : '#519451' }}
        >
          {report.deletedAt === null ? 'Desativar' : 'Ativar'}
        </Button>
      ),
    },
  ];

  return (
    <div className="adm-report-file-upload-container">
      <h2 style={{ textAlign: 'left', fontStyle: 'italic' }}>Gerenciar Relatórios</h2>
      <Button
        style={{ backgroundColor: '#323e56', color: '#fff', position: 'absolute', marginTop: -40, right: 60 }}
        onClick={() => setModalOpen(true)}
      >
        Adicionar Relatório
      </Button>

      <Modal
        title={<span style={{ fontStyle: 'italic' }}>Enviar Relatório</span>}
        visible={modalOpen}
        onCancel={() => setModalOpen(false)}
        footer={null}
        centered
      >
        <Form onFinish={handleUpload} layout="vertical" style={{ marginTop: 30 }}>
          <Form.Item label="Título" required style={{ marginBottom: -40}} >
            <Input
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Título do relatório"
            />
          </Form.Item>
          <Form.Item label="Descrição" required  style={{ marginBottom: -40}}>
            <Input.TextArea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Descrição do relatório"
              rows={6}
              style={{ resize: 'none' }}
            />
          </Form.Item>
          <Form.Item label="Selecionar Arquivo" required  style={{ marginBottom: -40}} >
            <Upload
              beforeUpload={() => false}
              onChange={handleFileChange}
              accept=".pdf,.docx,.xlsx"
            >
              <Button style={{ backgroundColor: '#4975ae', marginBottom: -60 }}>Escolher Arquivo <UploadOutlined style={{ marginLeft: 20 }} /> </Button>
            </Upload>
          </Form.Item>


          <Divider />

          <Form.Item style={{ marginBottom: -50, textAlign: 'right' }}>

          <Button
              type="default"
              onClick={() => setModalOpen(false)}
              style={buttonStyleCancel}
            >
              Cancelar
            </Button>

            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              style={buttonStyleAdd}
            >
              Enviar Relatório
            </Button>

          </Form.Item>
        </Form>
      </Modal>

      <Divider />

      <Table
        columns={columns}
        dataSource={reports}
        pagination={{ pageSize: 10 }}
        rowKey="key"
        bordered
      />

      <Modal
        title="Link para Distribuição"
        visible={isModalVisible}
        onCancel={handleModalClose}
        footer={[
          <Button key="close" onClick={handleModalClose}>
            Fechar
          </Button>,
        ]}
      >
        <p>https://macroai.com.br/download/{currentKey}</p>
      </Modal>
    </div>
  );
};

export default ReportController;
