import React from 'react';
import LogoMacro from '../../assets/img/png/LogoMacro.png';
import MacroBackground from '../../assets/img/png/macro_oficial.png';

const CompanySlide: React.FC = () => {
  const handleNavigateToMacro = () => {
    window.location.href = '/macro'; // Redireciona para a página /macro
  };

  return (
    <div
      style={{
        position: 'relative', // Permite posicionar o overlay
        height: '100%',
        backgroundImage: `url(${MacroBackground})`, // Imagem de fundo
        backgroundSize: 'cover', // Faz a imagem de fundo cobrir todo o slide
        backgroundPosition: 'center', // Centraliza a imagem de fundo
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      {/* Overlay preto */}
      <div
        style={{
          position: 'absolute', // Posiciona o overlay sobre a imagem
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.4)', // Cor preta com 50% de opacidade
          zIndex: 1, // Z-index inferior ao texto
        }}
      ></div>

      <img src={LogoMacro} alt="Logo Macro Agro" style={{ zIndex: 2 }} />
      <p style={{ zIndex: 2, marginTop: 60 }}>Comunidade de mercado e assessoria.</p>
      <button
        style={{ zIndex: 2, marginTop: 37 }}
        onClick={handleNavigateToMacro} // Chama a função de navegação
      >
        Saiba mais
      </button>
    </div>
  );
};

export default CompanySlide;
